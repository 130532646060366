





































































































import rService from '@/services/RequestService/RequestServiceSetup';
import { mapActions } from 'vuex';
export default {
  name: 'Blogs',
  data() {
    return {
      blogs: [],
      blog: null,
      loading: true,
      isBlog: false,
      titles: [],
      search: '',
      link: '',
      page: 1,
      titleSelected: '',
      itemsPerPage: 5,
      key: new Date().toString(),
    };
  },
  computed: {
    pages() {
      if (this.blog && this.blog.item) return this._.chunk(this.blog.item, this.itemsPerPage).length;
    },
    tablet() {
      return this.windowWidth >= 990;
    },
  },
  methods: {
    ...mapActions(['loadStatus']),
    goPost(entry) {
      window.open(entry.link);
    },
    select(title) {
      if (this.search == title) {
        this.search = '';
      } else {
        this.search = title;
      }
      this.$router.push({ name: 'Blogs', params: { link: this.link, post: this.search } }).catch();
      this.scrollTop();
    },
    backBlog() {
      this.$router.push({ name: 'Blogs', params: { link: '', post: '' } }).catch();
      this.isBlog = false;
      this.titles = [];
      this.search = '';
      this.link = '';
      this.globalStart();
      this.key = new Date().toString();
    },
    scrollTop() {
      const element = document.getElementById('v-main');
      element.scrollTop = 0;
    },
    processItems(item) {
      if (this.search) {
        item = item.filter((el) => {
          return el.title == this.search;
        });
      } else {
        return this._.chunk(item, this.itemsPerPage)[this.page - 1];
      }
      return item;
    },
    getHtml(item) {
      if (item['content:encoded']) {
        return item['content:encoded'];
      }
      if (item.content) {
        return item.content;
      }
    },
    goBlog(link: string) {
      window.open(link);
    },
    formatDate(dateString: string) {
      return this.$moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    },
    timeAgo(dateString: string) {
      return this.$moment(dateString).fromNow();
    },
    goFeed(linkSite: string) {
      this.loading = true;
      this.search = '';
      this.link = linkSite;
      this.blog = null;
      this.$router.push({ name: 'Blogs', params: { link: linkSite } }).catch();
      this.globalStart();
      this.key = new Date().toString();
    },
    async initialize() {
      this.blogs = await rService.get('blogs');
    },
    async initializeBlog(link) {
      const params = this.$router.currentRoute.params;
      const post = params.post ? params.post : '';
      const blog = await rService.post('blogs', { rss: link });
      this.blog = blog;
      this.titles = [];
      this.blog.item.forEach((element) => {
        this.titles.push(element.title);
      });
      if (post) {
        this.select(post);
      }
    },
    async globalStart() {
      this.loading = true;
      const params = this.$router.currentRoute.params;
      const link = params.link ? params.link : '';
      if (link) {
        await this.initializeBlog(link);
        this.isBlog = true;
      } else {
        this.isBlog = false;
        await this.initialize();
      }
      this.loading = false;
    },
  },
  mounted() {
    this.globalStart();
  },
};
